<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl mt-5 font-bold text-blue-800">Permissions</h1>
      <div class="flex flex-wrap xl:-mx-4 pb-5">
        <div class="w-full xl:my-4 xl:px-4">
          <p class="text-green-600" v-if="successMssg">
            Admin was created successful
          </p>
        </div>
      </div>
      <div class="flex flex-wrap xl:-mx-1">
        <div class="w-full xl:my-1 xl:px-1 xl:w-1/3">
          <!-- Column Content -->
          <div
            class="border-2 border-gray-200 bg-blue-50 p-5 rounded-md cursor-pointer"
            @click="
              $router.push({
                name: 'PermissionsDepartment',
              })
            "
          >
            <div class="flex justify-between">
              <div class="font-bold">Departments</div>
            </div>

            <p class="text-blue-800 mt-3 text-sm">
              Manage all the permissions attached to departments.
            </p>
          </div>
        </div>

        <div class="w-full xl:my-1 xl:px-1 xl:w-1/3">
          <!-- Column Content -->
          <div
            class="border-2 border-gray-200 p-5 bg-blue-50 rounded-md cursor-pointer"
            @click="
              $router.push({
                name: 'PermissionsUser',
              })
            "
          >
            <div class="flex justify-between">
              <div class="font-bold">Users</div>
            </div>
            <p class="text-blue-800 mt-3 text-sm">
              Manage all the permissions attached to users.
            </p>
          </div>
        </div>

        <div class="w-full xl:my-1 xl:px-1 xl:w-1/3">
          <!-- Column Content -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      firstName: "",
      lastName: "",
      email: "",
      selectLocation: "",
      assignRole: "",
      errorMssg: false,
      successMssg: false,
      promptModal: false,
      adminUserType: "",
      department: [
        "Marketing",
        "Credit Operations",
        "Corporate Sales",
        "Product Operations",
        "Chargeback Team",
        "Platform Monitoring Team",
        "Fraud Desk",
        "Settlement & Reconcillation",
        "Commercial Operations",
      ],
    };
  },
  computed: {
    ...mapGetters(["GET_LOCATIONS"]),
    filterLocations() {
      return this.GET_LOCATIONS.data;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {},
  methods: {
    async createAdmin() {
      let payload = {
        firstName: this.firstName.trim(),
        lastName: this.lastName.trim(),
        email: this.email.trim(),
        adminUserType: this.adminUserType,
        Department: this.assignRole,
      };
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("CREATE_ADMIN", payload);
        this.isLoading = false;
        if (res.status) {
          this.successMssg = true;
          // this.firstName = "";
          // this.lastName = "";
          // this.email = "";
          // this.selectLocation = "";
          // this.assignRole = "";
        } else {
          this.successMssg = false;
          this.errorMssg = true;
        }
      } catch (error) {}
    },
    closePromptModal() {
      this.promptModal = false;
      this.$router.push({ name: "Admin" });
    },
    resetForm() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.selectLocation = "";
      this.assignRole = "";
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
